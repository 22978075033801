<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.broker') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <BrokerField :label="$t('labels.broker')" v-model="form.broker_id" :errors="brokerErrors" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { BrokerField } from '../../brokers/components'
import { NAMESPACE } from '../store'
import DealInfoModalMixin from '../mixins/DealInfoModalMixin'

export default {
  name: 'BrokerModal',
  extends: BaseForm,
  mixins: [DealInfoModalMixin],
  props: {
    brokerId: [String, Number]
  },
  components: {
    VModalWidget,
    FormErrors,
    BrokerField
  },
  validations: {
    form: {
      broker_id: { required }
    }
  },
  data: () => ({
    form: {
      broker_id: null
    }
  }),
  computed: {
    brokerErrors() {
      const errors = []
      if (!this.$v.form.broker_id.$dirty) return errors
      !this.$v.form.broker_id.required && errors.push(this.$t('required'))
      this.getServerErrors('broker_id', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['setDealBroker']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        dealId: this.boardItem.entity.id,
        data: this.form
      }
      this.setDealBroker(payload)
        .then((resp) => {
          return this.getBoardItem({ url: this.entityURL })
        })
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    if (this.brokerId !== 'new') {
      this.form.broker_id = this.brokerId
    }
  }
}
</script>
